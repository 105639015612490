// Fonts sizes
$font-size-primary: 0.875rem; //14px
$font-size-primary-min: 0.75; // 12px

$font-size-xxxl: 2rem; //32px
$font-size-xxl: 1.438rem; //23px
$font-size-xl: 1.25rem; //20px
$font-size-lg: 1.125rem; //18px
$font-size-xm: 1.063rem; //17px
$font-size-md: 1rem; //16px
$font-size-xmd: 0.938rem; //15px
$font-size-sm: 0.875rem; //14px
$font-size-s: 0.813rem; //13px
$font-size-xs: 0.75rem; //12px
$font-size-xsm: 0.688rem; //12px
$font-size-xxs: 0.625rem; //10px
$font-size-xxxs: 0.563rem; //9px
