@font-face {
  font-family: "Poppins Regular";
  src: url(./poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: url(./poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: url(./rubik/Rubik-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik Bold";
  src: url(./rubik/Rubik-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./proxima-nova/Proxima-Nova.otf) format("opentype");
}

@font-face {
  font-family: "Fira Sans Condensed";
  src: url(./fira-sans-condensed/FiraSansCondensed-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url(./montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}
