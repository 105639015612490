@import "@angular/material/theming";
// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core();

// define a real custom palette (using http://mcg.mbitson.com)
/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #e9edfe,
  100: #c7d2fd,
  200: #a2b4fb,
  300: #7d96f9,
  400: #6180f8,
  500: #4569f7,
  600: #3e61f6,
  700: #3656f5,
  800: #2e4cf3,
  900: #1f3bf1,
  A100: #ffffff,
  A200: #f4f5ff,
  A400: #c1c8ff,
  A700: #a7b1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-accent: (
  50: #e7e7e7,
  100: #c2c2c2,
  200: #999999,
  300: #707070,
  400: #525252,
  500: #333333,
  600: #2e2e2e,
  700: #272727,
  800: #202020,
  900: #141414,
  A100: #ef6e6e,
  A200: #ea4040,
  A400: #f60000,
  A700: #dd0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// mandatory stuff for theming
$gaspar-palette-primary: mat-palette($md-primary);
$gaspar-palette-accent: mat-palette($md-accent);

// include the custom theme components into a theme object
$gaspar-theme: mat-light-theme($gaspar-palette-primary, $gaspar-palette-accent);

// include the custom theme object into the angular material theme
@include angular-material-theme($gaspar-theme);
