// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Custom things
@use "src/assets/scss/abstracts" as *;

// Plus imports for other components in your app.

// Our custom theming for the material library
@import "./assets/scss/custom_theme.scss";
@import "./assets/scss/custom_typography.scss";
@import "./assets/fonts/fonts.scss";

// ~~~~~~~~~~~~~~~~~~~~~~ GLOBAL STYLING ~~~~~~~~~~~~~~~~~~~~~~
html,
body {
  background-color: $color-background;
  height: 100%;
  font-family: "Fira Sans", sans-serif;
  color: black;
  margin: auto;
}
// Main application's container
.main-container {
  background-color: $color-background;
}

.primary-color {
  color: $color-primary;
}

.accent-colort {
  color: $color-accent;
}

p {
  font-family: Rubik;
  font-weight: 400;
  font-size: $font-size-lg;
  line-height: $line-height-primary;
  letter-spacing: normal;
}
h1 {
  font-family: Poppins Regular;
  font-weight: 600;
  font-size: $font-size-xxxl;
  line-height: $line-height-primary;
  letter-spacing: normal;
}
h2 {
  font-family: Poppins Regular;
  font-weight: 500;
  font-size: $font-size-xxl;
  line-height: $line-height-primary;
  letter-spacing: normal;
}
h3 {
  font-family: Poppins Regular;
  font-weight: 400;
  font-size: $font-size-primary;
  line-height: $line-height-primary;
  letter-spacing: normal;
}
h4 {
  font-family: Poppins Regular;
  font-weight: 400;
  font-size: $font-size-primary;
  line-height: $line-height-primary;
  letter-spacing: normal;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

// angular material uses a lot of span tags which makes it
// hard to change every single property, let's use instead a class
.gaspar-span {
  font-family: Proxima Nova;
  font-size: $font-size-xs;
  line-height: $line-height-primary;
  letter-spacing: normal;
}

// Markdown need the styles to be registered in the main file
// and also everything lives under a <p> html tag
.integration-status {
  margin-top: 20px;

  p {
    font-family: Rubik;
    font-size: $font-size-sm;
    color: #a1b3bf;
    line-height: 140%;
    word-break: break-word;
  }
}

.wrap-text {
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: $min-smart-phone) {
    white-space: nowrap;
  }
}

.disabled {
  background: #f4f4f4;
  color: rgba(51, 51, 51, 0.5);
}

.aligned {
  display: flex;
  align-items: center;
}

.centered {
  display: flex;
  justify-content: center;
}

// ~~~~~~~~~~~~~~~~~~~~~~ GASPAR SPECIFIC ~~~~~~~~~~~~~~~~~~~~~~
.gaspar-title {
  font-family: Rubik;
  font-style: normal;
  font-size: $font-size-xxxl;
  font-weight: 700;
  line-height: 38.4px;
}

.gaspar-secondary-title {
  font-family: Rubik Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.gaspar-sub-title {
  font-family: Rubik Bold;
  font-style: normal;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  color: $color-primary;
}

.gaspar-card {
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.04) !important;
  background-color: #ffffff;
}

.gaspar-table {
  font-size: $font-size-md;
  line-height: 100%;
  padding: 12px 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: auto;
  min-width: 700px;
  text-align: start;
  justify-content: center;
  align-items: center;
  word-break: break-word;

  thead {
    tr {
      th {
        font-family: Rubik Bold;
        font-weight: 500;
        // Override the default mat style if exists.
        color: inherit;
      }
    }
  }

  tbody {
    tr {
      td {
        font-family: Rubik;
        font-weight: 400;
      }
    }
  }

  // Angular material overriden classes
  .mat-cell {
    font-size: $font-size-xmd !important;
    font-family: Rubik !important;
  }

  .mat-header-cell {
    font-size: $font-size-xmd !important;
    font-family: Rubik Bold !important;
    color: black;
  }

  .action-icon {
    padding: 0px 15px 0px 0px;
  }

  .no-available-data {
    text-align: center;
    font-size: $font-size-lg;
    margin: 20px;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;

    .tag-container {
      word-wrap: break-word;
      margin: 5px;
    }
  }

  .base-col {
    width: 15%;
  }
}

.gaspar-form {
  width: 100%;

  .login-form {
    padding: 16px;
  }

  .sub-header {
    margin-bottom: 5px; // To avoid the header from overlapping the form field.
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 18px;
    color: $color-medium-gray;
  }
}

.gaspar-primary-button {
  font-family: Fira Sans Condensed;
  font-size: $font-size-lg;
  font-style: normal;
  line-height: 22px;
  font-weight: 500;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: $color-dark-darker-blue;
  animation: spinner 0.8s linear infinite;
}

.blur {
  filter: blur(4px);
}

.badge-popover {
  position: relative;
  top: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.24);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 5px 32px;
  width: max-content;
  text-align: center;

  h3.title {
    margin: 5px 0 20px 0;
  }

  .logout-btn {
    height: 26px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    margin: 10px 0 10px 0;
  }
}

// ~~~~~~~~~~~~~~~~~~~~~~ ANGULAR MATERIAL SPECIFIC ~~~~~~~~~~~~~~~~~~~~~~

// For all the angular mat menu, center horizontally every single item.
.gaspar-menu + * .mat-menu-panel .mat-menu-item .item {
  display: flex;

  img {
    margin-right: 10px;
  }

  &.delete {
    color: red;
  }
}

// Input styles
// Standard
::ng-deep .mat-form-field {
  .mat-input-element {
    color: $color-dark-darker-blue;
  }
  .mat-form-field-label {
    color: $color-dark-darker-blue;
  }
  .mat-form-field-underline {
    background-color: $color-dark-darker-blue;
  }
  .mat-form-field-ripple {
    background-color: $color-dark-darker-blue;
  }
  .mat-form-field-required-marker {
    color: $color-dark-darker-blue;
  }
}
// Focused
::ng-deep .mat-form-field.mat-focused {
  .mat-form-field-label {
    color: $color-dark-dark-blue;
  }
  .mat-form-field-ripple {
    background-color: $color-dark-dark-blue;
  }
  .mat-form-field-required-marker {
    color: $color-dark-dark-blue;
  }
  .mat-input-element {
    color: $color-dark-dark-blue;
  }
}
// Invalid
::ng-deep .mat-form-field.mat-form-field-invalid {
  .mat-input-element {
    color: $color-accent;
  }
  .mat-form-field-label {
    color: $color-accent;
    .mat-form-field-required-marker {
      color: $color-accent;
    }
  }
  .mat-form-field-ripple {
    background-color: $color-accent;
  }
}

// Disabled
::ng-deep .mat-form-field.mat-form-field-disabled {
  .mat-input-element {
    color: $color-dark-dark-blue;
  }
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      background-color: #f4f4f4 !important;
    }
  }
}

// Modals
.gaspar-modal {
  overflow-y: auto;
  // A bit more of top
  margin-top: -40px;

  .mat-dialog-container {
    width: 80vw;
    max-width: 500px;
  }

  .actions {
    display: flex;
    justify-content: space-around;

    .left {
      margin-right: auto;
    }
  }
}

// Modals
.gaspar-modal-large {
  overflow-y: auto;
  // A bit more of top
  margin-top: -40px;

  .mat-dialog-container {
    width: 110vw;
    max-width: 700px;
  }

  .actions {
    display: flex;
    justify-content: space-around;

    .left {
      margin-right: auto;
    }
  }
}

// Forms
.form-dropdown {
  text-align: center;
  background: white;
  border-radius: 16px;
  border: 1px solid #c4c4c4;
  font-size: 16px;
  line-height: 36px;
  color: #333333;
  margin-bottom: 25px;

  &.mat-select-disabled {
    background: #f4f4f4;
    color: #333333;
  }
}
// The arrow of the drop down input
::ng-deep .mat-select-arrow {
  margin-right: 20px !important;
}
// globally disable mat-drawer scrollbar (Theres no other way to disable it)
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

/* this makes it so that sort header arrow is always shown: */
.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
  opacity: 0.7 !important;
}

/* this changes the active color to the mat toggles */
::ng-deep .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: $color-background;
}
::ng-deep .mat-slide-toggle .mat-slide-toggle-bar {
  background-color: $color-background;
}

::ng-deep
  .mat-slide-toggle.mat-checked:not(.mat-disabled)
  .mat-slide-toggle-thumb {
  background-color: $color-dark-darker-blue;
}
::ng-deep .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: $color-dark-darker-blue;
}

.mat-tooltip.tooltip {
  color: $color-grey;
  font-family: Rubik;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size-xsm;
  line-height: 17px;
  background-color: #fff;
}

// ~~~~~~~~~~~~~~~~~~~~~~ GENERIC ~~~~~~~~~~~~~~~~~~~~~~
.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.text-center {
  text-align: center;
}

.text-break {
  word-break: break-word;
  white-space: pre-wrap;
}

.scrollable {
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.grey-dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #e0e0e0;
}

.tabs-container {
  display: flex;
  align-items: center;
  margin: 12px 0 12px 0;
  font-family: Poppins Regular !important;
  font-style: normal;
  font-weight: 700;
  color: black;
  font-size: $font-size-md;
  line-height: 21px;
  color: #333333;

  .right {
    margin-left: auto;

    .inner {
      display: flex;
    }
  }

  // Inherit the global settings for span
  span {
    font-family: Poppins Bold;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-sm;
    line-height: 21px;

    .active {
      color: inherit;
    }

    .disabled {
      color: $color-grey;
    }
  }
  .tab-btn {
    background-color: inherit;
    padding: 10px;
    margin: 0 10px 0 10px;
    height: fit-content;
    border-bottom: 2px solid transparent;
    color: $color-grey;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      color: black;
      border-radius: 0;
      border-bottom: 2px solid $color-dark-darker-blue;
    }
  }
}

.connected-line {
  &:before {
    position: absolute;
    border: 1px solid $color-primary;
    border-width: 0.5px;
    height: 14px;
    content: "";
    left: 20%;
    margin-left: -1px;
    margin-top: -26px;
    background-color: $color-primary;
  }
}

.vertical-line {
  position: absolute;
  border: 1px solid $color-primary;
  border-width: 0.5px;
  height: 80px;
  content: "";
  left: 20%;
  margin-left: -1px;
  background-color: $color-primary;

  &.half {
    height: 40px;
    margin-top: -16px;
  }
}

/* MODAL STYLES
-------------------------------*/
app-modal {
  /* modals are hidden by default */
  display: none;

  .gaspar-modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* z-index must be higher than .jw-modal-background */
    z-index: 1000;

    /* enables scrolling for tall modals */
    overflow: auto;

    .gaspar-modal-body {
      // padding: 20px;
      background: #fff;

      /* margin exposes part of the modal background */
      // margin: 40px;
    }
  }

  // .gaspar-modal-background {
  //     /* modal background fixed across whole screen */
  //     position: fixed;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;

  //     /* semi-transparent black  */
  //     background-color: #000;
  //     opacity: 0.75;

  //     /* z-index must be below .jw-modal and above everything else  */
  //     z-index: 900;
  // }
}

body.gaspar-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}
// ~~~~~~~~~~~~~~~~~~~~~~ MEDIA EXAMPLES ~~~~~~~~~~~~~~~~~~~~~~
// @media (min-width: 320px) {
//   /* smartphones, iPhone, portrait 480x320 phones */
//   .main-container {
//     background: red;
//   }
// }
// @media (min-width: 481px) {
//   /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
//   .main-container {
//     background: green;
//   }
// }
// @media (min-width: 641px) {
//   /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
//   .main-container {
//     background: red;
//   }
// }
// @media (min-width: 961px) {
//   /* tablet, landscape iPad, lo-res laptops ands desktops */
//   .main-container {
//     background: green;
//   }
// }
// @media (min-width: 1025px) {
//   /* big landscape tablets, laptops, and desktops */
//   .main-container {
//     background: red;
//   }
// }
// @media (min-width: 1281px) {
//   /* hi-res laptops and desktops */
//   .main-container {
//     background: green;
//   }
// }
