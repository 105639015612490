// Colors
$color-primary: #4569f7;
$color-accent: #333333;
$color-light-gray: #c3c3c3;
$color-medium-gray: #828282;
$color-background: #f5f8fa;
$color-grey: #767e9d;
$color-warning-text: #a1b3bf;

// Secondary colors
$color-dark-dark-blue: #373f62;
$color-hover-dark-blue: #2f3650;
$color-dark-darker-blue: #252c4a;
$color-text-green: #2ecc71;
